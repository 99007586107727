import React from "react"
import { graphql, Link } from "gatsby"
import {
  Box,
  Grid,
  makeStyles,
  Divider,
  Container,
  useMediaQuery,
} from "@material-ui/core"
import { Title, Text, DividedSection } from "gatsby-theme-material-foundry"
import Layout from "../components/layout"
import SEO from "../components/SEO/seo"
import { AvatarCard } from "../components/event-cards"
import { useTheme } from "@material-ui/styles"
import { Map, NewLogoSvg } from "../components/svgs"
import { navigate } from "@reach/router"
import { ContactFormModal } from "../components/forms/contact-form"

const useStyles = makeStyles(theme => ({
  bottomsection: {
    position: "relative",
  },
  logoCircle: {
    backgroundColor: "rgba(255,255,255, 1)",
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "400px",
    },
  },
  cards: {
    backgroundColor: theme.palette.black.main,
  },
  hovered: {
    backgroundColor: theme.palette.black.light,
  },
  aboutContainer: {
    color: "white",
  },
  greenSection: {},
  imgCard: {
    borderRadius: "6px !important",
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    maxWidth: "100%",
    height: "auto",
  },
  avatar: {
    margin: 10,
  },
  avatarname: {
    margin: "5px",
    padding: "0",
  },
  bigAvatar: {
    width: 100,
    height: 100,
  },
  title: {
    padding: "0",
  },
  subtitle: {
    margin: "0",
    padding: "0",
    fontWeight: "400",
    color: "white",
  },
}))

function AboutPage(props) {
  const classes = useStyles()
  const [hovered, setHovered] = React.useState("")
  const theme = useTheme()
  const onHover = data => setHovered(data)
  const onClick = data => navigate(`/campus/${data.toLowerCase()}/`)
  return (
    <Layout>
      <SEO postTitle="Om HVL Skape" slug="about" />
      <DividedSection
        black
        height={useMediaQuery(theme.breakpoints.up("sm")) ? "600px" : "400px"}
        image={props.data.cover.childImageSharp.fixed.src}
        backgroundBlendMode="overlay"
        backgroundColor="#555"
      >
        <Container align="center" maxWidth="sm">
          <Box mt="100px" className={classes.logoCircle}>
            <NewLogoSvg width="200px" height="200px" />
          </Box>
        </Container>
      </DividedSection>
      <DividedSection backgroundColor={theme.palette.black.main}>
        <Container
          align="left"
          maxWidth="md"
          className={classes.aboutContainer}
        >
          <Box mt={useMediaQuery(theme.breakpoints.up("sm")) ? 14 : 20}>
            <Title
              variant="h3"
              component="h1"
              color="primary"
              className={classes.title}
            >
              Om HVL Skape
            </Title>
            <Title color="inherit" variant="h4">
              Høgskulen på Vestlandets innovasjons- og entreprenørskapsatsning
            </Title>
            <Text variant="body1" paragraph>
              HVL Skape er Høgskulen på Vestlandet sitt studenttilbud innenfor
              innovasjon og entreprenørskap som strekker seg utover det som
              skjer i fag og emner. Som HVL-student kan du gjennom HVL Skape få
              hjelpe til å videreutvikle ideene og prosjektene dine, delta på
              arrangement og få finansiering. Selv om aktivitetene innenfor HVL
              Skape er tilbud som er «utenfor klasserommet», er vi opptatt av å
              være godt koordinert med utdanningen til studentene våre. Dette
              mener vi gir bedre ideer, større sjanse for å lykkes, og sikrer at
              studentene våre får en formalkompetanse i bunn. Mange av
              studentene jobber videre med prosjektene fra et fag eller en
              oppgave gjennom HVL Skape.
            </Text>
            <Text variant="body1" paragraph>
              HVL Skape er ikke bare et tilbud til studentene våre som ønsker å
              starte egen bedrift. De fleste av våre studenter utdanner seg til
              en profesjon, og skal inn i ordinært arbeidsliv i offentlig eller
              privat sektor etter endte studier. Vi ønsker likevel å gi alle
              studentene våre entreprenørielle ferdigheter og erfaring med
              innovasjonsmetodikk, og slik bidra til at våre studenter blir
              endringsagenter både i helsevesenet, i skole og barnehage, eller i
              næringslivet.
            </Text>
            <Text variant="body1" paragraph>
              HVL Skape har som mål å fremme og aktivt jobbe med «ansvarlig
              innovasjon». Med dette mener vi all innovasjon som tar sikte på å
              løse samfunnsutfordringer knyttet til klima, helse og sosial
              ulikhet. Innovasjon handler heller ikke bare om nye produkter og
              produksjonsprosesser, men også nye tjenester, organisasjonsformer
              eller forretningsmodeller. Dette kan eksempelvis omhandle nye
              måter å tilrettelegge for læring i skolen eller for samhandling
              mellom kommunale ansatte og brukere. I så måte er også HVL Skape
              opptatt av å tilrettelegge for innovasjon i offentlig sektor i
              tillegg til i privat sektor.
            </Text>
            <Text variant="body1" paragraph>
              HVL Skape er et tilbud for alle studieretninger og på alle campus.
              Ta gjerne kontakt for å finne ut mer.
            </Text>
          </Box>
        </Container>
      </DividedSection>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          style={{
            backgroundColor: useMediaQuery(theme.breakpoints.up("sm"))
              ? "transparent"
              : theme.palette.black.main,
          }}
        >
          <Container
            maxWidth="sm"
            style={{
              color: useMediaQuery(theme.breakpoints.up("sm"))
                ? theme.palette.black.main
                : "white",
            }}
          >
            <Box p={2}>
              <Title variant="h2" color="inherit" align="center">
                Campus
              </Title>
              <Divider />
              <Text variant="h5" component="p" color="inherit">
                HVL Skape er tilstede på alle campus, og har et variert utvalg
                av infrastuktur,{" "}
                <Link to="/tjenester" style={{ color: "#64d0df" }}>
                  tjenester
                </Link>{" "}
                og{" "}
                <Link to="/arrangement" style={{ color: "#64d0df" }}>
                  arrangement
                </Link>{" "}
                for studenter. Klikk på ditt campus for å lese mer.
              </Text>
            </Box>
            <Box mx={2} my={4}>
              <Title
                variant="h4"
                className={classes.title}
                gutterBottom
                color="inherit"
              >
                Vil du vite mer om oss?
              </Title>
              <Text color="inherit" variant="h5" component="p">
                Send oss en melding sentralt så tar vi kontakt så snart vi har
                anledning.
              </Text>
              <ContactFormModal />
            </Box>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} lg={6} align="right">
          <Box overflow="hidden">
            <Map
              fill={theme.palette.black.main}
              fill2={theme.palette.primary.main}
              height="50vw"
              onHover={onHover}
              hovered={hovered}
              onClick={onClick}
              hoverColor={theme.palette.black.light}
            />
          </Box>
        </Grid>
      </Grid>
      <DividedSection
        backgroundColor={theme.palette.secondary.light}
        className={classes.bottomsection}
      >
        <Container align="center" maxWidth="md">
          <Title variant="h3" black gutterBottom id="kontakt-oss">
            Kontakt oss
          </Title>
          <Box mb={6}>
            <Grid container>
              {props.data.allPeople.nodes.map(person => (
                <Grid item xs={6} sm={3}>
                  <AvatarCard
                    name={`${person.fornavn} ${person.etternavn}`}
                    role={person.skapestilling}
                    email={person.emailaddress}
                    img={person.avatar}
                    imageClasses={classes.bigAvatar}
                    link={person.hvlprofilside}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </DividedSection>
    </Layout>
  )
}
export const ItemPageQuery = graphql`
  query People {
    cover: file(name: { eq: "om_oss" }) {
      childImageSharp {
        fixed(width: 2000) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    allPeople: allFolk {
      nodes {
        avatar
        fornavn
        etternavn
        navn
        stilling
        skapestilling
        email
        hvlprofilside
        linkedin
      }
    }
  }
`

export default AboutPage
