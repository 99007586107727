import React from "react"
import {
  Divider,
  TextField,
  Box,
  LinearProgress,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@material-ui/core"
import Modal from "../modal"
import { Title, Text, Button as FoundryButton } from "gatsby-theme-material-foundry"
import { makeStyles } from "@material-ui/core/styles"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { submit } from "./submit"

const validationSchema = Yup.object().shape({
  mittNavn: Yup.string().required("Du må skrive et navn"),
  checked: Yup.boolean().oneOf([true], "Du må godkjenne personværnerklæringen"),
  minEmail: Yup.string()
    .required("Du må skrive en epost")
    .email("må være en epost"),
  beskrivelse: Yup.string()
    .min(50, "Meldingen må være minimum 50 bokstaver")
    .required("Meldingen må være minimum 50 bokstaver"),
})

const useStyles = makeStyles(theme => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.black.main,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.black.main}`,
      },
    },
  },
  "& input:valid:focus + fieldset": {
    borderColor: theme.palette.black.main,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  field: {
    margin: theme.spacing(1),
  },
}))

const ContactForm = ({ handleClose }) => {
  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        mittNavn: "",
        minEmail: "",
        beskrivelse: "",
        checked: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setStatus, setSubmitting }) =>
        submit({
          resetForm: resetForm,
          setStatus: setStatus,
          setSubmitting: setSubmitting,
          handleClose: handleClose,
          email: {
            content: [
              { text: "Type", value: values.student },
              { text: "Navn", value: values.mittNavn },
              { text: "Email", value: values.minEmail },
              { text: "Telefon", value: values.minTelefon },
              { text: "Beskrivelse", value: values.beskrivelse },
            ],
            toEmail: values.minEmail,
            name: values.mittNavn,
            fromEmail: "skape@hvl.no",
            ccEmail: ["skape@hvl.no"],
            title: "Takk for din melding",
            type: "makerspace",
            description: `Takk for at du sender in en melding til oss. Vi kontakter deg så raskt som mulig men hvis du ikke hører noe fra oss så kan du alltid svare på denne mailen.`,
            subject: `Ny melding fra ${values.mittNavn} via hvlskape.no`,
          },
        })
      }
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        status,
        isSubmitting,
      }) => (
        <Form noValidate autoComplete="off" className={classes.root}>
          <Divider style={{ marginBottom: 20 }} />

          <TextField
            name="mittNavn"
            helperText={touched.mittNavn ? errors.mittNavn : ""}
            error={Boolean(errors.mittNavn)}
            label="Mitt navn"
            margin="dense"
            required
            variant="outlined"
            value={values.mittNavn}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="minEmail"
            type="email"
            margin="dense"
            required
            helperText={touched.minEmail ? errors.minEmail : ""}
            error={Boolean(errors.minEmail)}
            label="Min Email"
            variant="outlined"
            value={values.minEmail}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <Title black variant="h5">
            Melding
          </Title>
          <TextField
            margin="dense"
            label="Melding"
            required
            name="beskrivelse"
            helperText={touched.beskrivelse ? errors.beskrivelse : ""}
            error={Boolean(errors.beskrivelse)}
            type="text"
            onChange={handleChange}
            value={values.beskrivelse}
            fullWidth
            variant="outlined"
            multiline
            onBlur={handleBlur}
            rows="4"
          />
          <Box mt={2}>
            <FormControl
              required
              error={Boolean(errors.checked)}
              component="fieldset"
            >
              <FormLabel component="legend">Personværnerklæring</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.checked}
                    onChange={handleChange}
                    value="checked"
                    name="checked"
                    color="primary"
                  />
                }
                label={
                  <a
                    href="https://hvlskape.no/personvern"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jeg godkjenner personværnerklæringen
                  </a>
                }
              />
              <FormHelperText>{errors.checked}</FormHelperText>
            </FormControl>
          </Box>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          {isSubmitting && <LinearProgress />}
          {status && <span>{status}</span>}
          <Box align="right">
            <FoundryButton
              className={classes.button}
              onClick={handleClose}
              color="primary"
              simple
              variant="contained"
              // disabled={!isValid || values.people.length === 0}
            >
              avslutt
            </FoundryButton>
            <FoundryButton
              className={classes.button}
              type="submit"
              disabled={Boolean(!isValid)}
              color="primary"
              variant="contained"
              // disabled={!isValid || values.people.length === 0}
            >
              send melding
            </FoundryButton>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export function ContactFormModal() {
  const [open, setOpen] = React.useState(false)
  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      buttonLabel="Send Melding"
      handleOpen={handleOpen}
      handleClose={handleClose}
      modalTitle="Send en melding til HVL Skape"
      modalDescription={
        <Text info>Her kan du sende en generell melding til HVL Skape.</Text>
      }
    >
      <ContactForm handleClose={handleClose} label="Send Melding" />
    </Modal>
  )
}

export default ContactForm
