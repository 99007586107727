import React from "react"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  IconButton,
} from "@material-ui/core"
import {
  Title,
  Button as FoundryButton,
} from "gatsby-theme-material-foundry"
import { useTheme } from "@material-ui/styles"
import CloseIcon from "@material-ui/icons/Close"

export function Modal({ color = "primary", open, buttonLabel, handleOpen, handleClose, modalTitle, modalDescription, children }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <React.Fragment>
      <FoundryButton
        variant="contained"
        color={color}
        size="lg"
        onClick={handleOpen}
      >
        {buttonLabel}
      </FoundryButton>
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Title black variant="h4">
              {modalTitle}
            </Title>

            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
           {modalDescription}
          </Box>
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default Modal